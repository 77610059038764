import moment from "moment";

const date = moment().format("YYYY-MM-DD");

const initialState = {
  // uncomment for testing
  // dataBooking: {
  //   insured: "",
  //   insuredName: "TEST-ABED",
  //   insuredType: "WNI",
  //   insuredIdentifyNumber: "1234123412341234",
  //   insuredAddress: "Jl. Jend. Sudirman",
  //   insuredPhoneNumber: "081234567890",
  //   insuredBirthPlace: "Sinjai",
  //   insuredGender: "Pria",
  //   insuredBirthDate: "1996-10-07",
  //   insuredEmail: "abed@gmail.com",
  //   insuredHeirName: "",
  //   policyStartDate: date,
  // },
  dataBooking: {
    insured: "",
    insuredName: "",
    insuredType: "WNI",
    insuredIdentifyNumber: "",
    insuredAddress: "",
    insuredPhoneNumber: "",
    insuredBirthPlace: "",
    insuredGender: "",
    insuredBirthDate: "",
    insuredEmail: "",
    insuredHeirName: "",
    policyStartDate: date,
  },
  dataSummary: null,
  dataFoto: {
    file: null,
    url: null,
  },
};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DATA_BOOKING":
      return {
        ...state,
        dataBooking: action.payload,
      };
    case "SET_DATA_SUMMARY":
      return {
        ...state,
        dataSummary: action.payload,
      };
    case "SET_DATA_FOTO":
      return {
        ...state,
        dataFoto: action.payload,
      };
    case "RESET_DATA_BOOKING":
      return (state = initialState);
    default:
      return state;
  }
};

export default bookingReducer;
