import React, { useEffect, useState, useRef } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import Detail from "../../components/Detail";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import SecondaryButton from "../../components/Button/SecondaryButton";
import Loading from "../../components/Loading";
import Jumbotron from "../../components/Jumbotron";
import "./HistoryDetail.css";
import axiosGet from "../../helpers/axiosGet";
import StatusPill from "../../components/StatusPill";

export default function HistoryDetail() {
  const scroll = useRef(null);
  const executeScroll = () => scroll.current.scrollIntoView();
  const history = useHistory();

  const { id } = useParams();

  useEffect(() => {
    const decode = atob(id);

    axiosGet({
      url: `policy/id/${decode}`,
      callback: (res) => {
        setLoading(false);
        setDetail(res.data);
        setFiles([
          // {
          //   title: "Identify File",
          //   type: "identifyFile",
          //   value: res.data.files.identifyFile,
          //   fileName: `${res.data.policyNumber}-Identity`,
          // },
          {
            title: "Invoice",
            type: "invoice",
            value: res.data.files.invoice,
            fileName: `${res.data.policyNumber}-Invoice`,
          },
          {
            title: "Policy",
            type: "policy",
            value: res.data.files.policy,
            fileName: `${res.data.policyNumber}`,
          },
        ]);
      },
      errorCallback: (err) => {
        console.log(err);
        history.push("/404");
        setLoading(false);
      },
    });
  }, [history, id]);

  const [loading, setLoading] = useState(true);
  const [detail, setDetail] = useState({});
  const [files, setFiles] = useState([]);

  return (
    <>
      <Jumbotron />

      <section className="create-main-content" ref={scroll}>
        <div className="text-center">
          <h3 className="font-weight-bold">POLICY DETAIL</h3>
          <p className="mb-1">SCHEDULE PERSONAL ACCIDENT CO-PRODUCT</p>
          <hr className="create-divider-primary create-divider-big" />
        </div>
        <div className="mt-5">
          <p className="mb-1">Pembelian Detail</p>
          <hr className="create-divider-primary create-divider-small" />
        </div>

        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="mt-3">
              <MDBContainer>
                <MDBRow className="status-justify">
                  <StatusPill value={detail.status} />
                </MDBRow>
              </MDBContainer>
            </div>

            <Detail data={detail} />

            <div className="mt-5">
              <MDBRow className="create-row-gap">
                {files.length &&
                  files.map((file) => {
                    return file.value ? (
                      <DownloadFiles
                        id={detail.id}
                        file={file}
                        key={file.title}
                      />
                    ) : null;
                  })}

                {/* <DownloadFiles
                  clause
                  file={{
                    title: "Klausula",
                    url:
                      detail.incuranceCode === "PMS-INS-0161"
                        ? "/GEGI_PSAKDI.pdf"
                        : "/klausula.pdf",
                    fileName:
                      detail.incuranceCode === "PMS-INS-0161"
                        ? "GEGI_PSAKDI.pdf"
                        : "klausula.pdf",
                  }}
                /> */}
                <DownloadFiles
                  id={detail.id}
                  file={{
                    title: `${detail.insuranceCode}`,
                    type: `clause`,
                    fileName: `${detail.insuranceCode}-Klausula`,
                  }}
                />
              </MDBRow>
            </div>

            <MDBContainer className="create-button-container">
              <MDBRow>
                <Link to="/history" onClick={executeScroll}>
                  <SecondaryButton text="Back" />
                </Link>
              </MDBRow>
            </MDBContainer>
          </>
        )}
      </section>
    </>
  );
}

const DownloadFiles = ({ id, file, clause }) => {
  const { title, type, fileName, url } = file;

  const [loading, setLoading] = useState(false);

  const handleDownload = () => {
    setLoading(true);
    console.log(file);

    axiosGet({
      url: `/policy/id/${id}/download-file/${type}`,
      responseType: "blob",
      callback: (res) => {
        setLoading(false);
      },
      errorCallback: (res) => {
        setLoading(false);

        let ext = "";
        if (res.type === "image/png") {
          ext = "png";
        } else if (res.type === "image/jpeg") {
          ext = "jpg";
        } else {
          ext = "pdf";
        }

        const blob = new Blob([res]);
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", `${fileName}.${ext}`);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      },
    });
  };

  return (
    <MDBCol md="6" lg="4">
      <div className="border rounded bg-white px-4 py-2 position-relative overflow-hidden">
        <div className="create-bg-ellipse"></div>
        <MDBRow>
          <MDBCol size="4" middle>
            {clause ? (
              <a href={url} download>
                <MDBIcon
                  icon="cloud-download-alt"
                  size="2x"
                  className="create-color-primary"
                />
              </a>
            ) : loading ? (
              <div
                className="spinner-border create-color-primary"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleDownload(type)}
              >
                <MDBIcon
                  icon="cloud-download-alt"
                  size="2x"
                  className="create-color-primary"
                />
              </div>
            )}
          </MDBCol>

          <MDBCol size="8">
            <p className="font-weight-bolder mb-1">{`Download ${title}`}</p>
            <p className="create-text-small font-weight-bolder mb-0">
              {fileName}
            </p>
          </MDBCol>
        </MDBRow>
      </div>
    </MDBCol>
  );
};
