import React from "react"
import Boy from "../img/boy.svg"
import Ellipse from "../img/ellipse.svg"
import Healthcare from "../img/healthcare.svg"
import moment from "moment"
import { MDBContainer, MDBRow, MDBCol } from "mdbreact"
import CurrencyFormat from "../components/CurrencyFormat"

export default function Detail({ data }) {
  return (
    <>
      <div className="border rounded mt-5 create-bg-white">
        <MDBContainer className="px-5 py-4 border-bottom position-relative">
          <p className="create-text-small">Nama Tertanggung</p>
          <p className="h5 mb-0 font-weight-bold">{data.insuredName}</p>
          <img src={Boy} alt="boy" className="boy" />
        </MDBContainer>

        <MDBContainer className="px-5 py-4">
          <MDBRow className="create-row-gap-big">
            <MDBCol md="6">
              <p className="create-text-small">
                {data.insuredType === "WNI" ? "No KTP" : "No KITAS"}
              </p>
              <p className="mb-0 font-weight-bold">
                {data.insuredIdentifyNumber}
              </p>
            </MDBCol>
            <MDBCol md="6">
              <p className="create-text-small">No Telp</p>
              <p className="mb-0 font-weight-bold">{data.insuredPhoneNumber}</p>
            </MDBCol>
            <MDBCol md="6">
              <p className="create-text-small">Alamat Tertanggung</p>
              <p className="mb-0 font-weight-bold">{data.insuredAddress}</p>
            </MDBCol>
            <MDBCol md="6">
              <p className="create-text-small">Email</p>
              <p className="mb-0 font-weight-bold">{data.insuredEmail}</p>
            </MDBCol>
            <MDBCol md="6">
              <p className="create-text-small">Tempat Tanggal Lahir</p>
              <p className="mb-0 font-weight-bold">
                {data.insuredBirthPlace},{" "}
                {moment(data.insuredBirthDate)
                  // .locale("id", localization)
                  .format("D-M-YYYY")}
              </p>
            </MDBCol>
            <MDBCol md="6">
              <p className="create-text-small">Nama Ahli Waris</p>
              <p className="mb-0 font-weight-bold">
                {data.insuredHeirName ? data.insuredHeirName : "-"}
              </p>
            </MDBCol>
            <MDBCol md="6">
              <p className="create-text-small">Jenis Kelamin</p>
              <p className="mb-0 font-weight-bold">{data.insuredGender}</p>
            </MDBCol>
            {data.transactionDate && (
              <MDBCol md="6">
                <p className="create-text-small">Tanggal Pembelian</p>
                <p className="mb-0 font-weight-bold">
                  {moment(data.transactionDate).format("DD-MM-YYYY HH:mm:ss")}
                </p>
              </MDBCol>
            )}
          </MDBRow>
        </MDBContainer>
      </div>

      <div className="border rounded mt-5 create-bg-white position-relative">
        <MDBContainer className="border-bottom px-5 py-3">
          <MDBRow>
            <MDBCol middle md="6">
              <MDBContainer>
                <MDBRow>
                  <div className="create-gradient-primary create-text-small mb-0 text-white font-weight-bold py-2 px-4 rounded-pill">
                    Product Detail
                  </div>
                </MDBRow>
              </MDBContainer>
            </MDBCol>
            {data.insuranceName && (
              <MDBCol md="6">
                <p className="create-text-small insurance-name-text">
                  Insurance Name
                </p>
                <p className="mb-0 font-weight-bold">{data.insuranceName}</p>
              </MDBCol>
            )}
          </MDBRow>
        </MDBContainer>
        <MDBContainer className="border-bottom px-5 py-4">
          <MDBRow>
            <MDBCol md="6">
              <p className="create-text-small">Total Premium</p>
              <p className="h5 font-weight-bold">{`${
                data.currencyCode
              } ${CurrencyFormat(data.premium)}`}</p>
              <p className="create-text-small mb-0">
                IDR 100,000,000.00 x 0.1000%
              </p>
            </MDBCol>
            <MDBCol md="6">
              <p className="create-text-small">Tanggal Pertanggungan</p>
              <p className="h5 font-weight-bold policy-date-text">
                From {moment(data.policyStartDate).format("D MMMM YYYY")} To{" "}
                {moment(
                  data.policyEndDate ? data.policyEndDate : data.endDate
                ).format("D MMMM YYYY")}
              </p>
              <p className="create-text-small mb-0 policy-date-wording-text">
                {data.policyDateWording}
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <MDBContainer>
          <MDBRow className="border-bottom">
            <MDBCol md="4" className="px-5 py-4 border-right">
              <p className="create-text-small">
                {`Jenis Pertanggungan & Suku Premi`}
              </p>
              <p className="h5 font-weight-bold">
                {data.coverages &&
                  data.coverages.length &&
                  data.coverages[0].rate}
                {data.coverages &&
                data.coverages.length &&
                data.coverages[0].rateType === "percentage"
                  ? "%"
                  : ""}
              </p>
              <p className="create-text-small mb-0">
                {data.coverages &&
                  data.coverages.length &&
                  data.coverages[0].coverageName}
              </p>
            </MDBCol>
            <MDBCol md="5" className="px-5 py-4 border-right">
              <p className="create-text-small">
                Resiko Sendiri <br />
                Deductible
              </p>
              <p className="h5 font-weight-bold mb-0">0.000 Flat</p>
            </MDBCol>
            <MDBCol md="3" className="px-5 py-4">
              <p className="create-text-small">
                {`Type of Coverage & Sum Insured`} <br />
                {data.coverages &&
                  data.coverages.length &&
                  `${data.coverages[1].coverageName}`}
              </p>
              <p className="h5 font-weight-bold mb-0">
                {data.coverages &&
                data.coverages.length &&
                data.coverages[1].rate
                  ? `${data.coverages[1].rate}`
                  : "-"}
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <MDBContainer>
          <MDBRow>
            <MDBCol md="4" className="px-5 py-4">
              <p className="create-text-small">
                {data.details &&
                  data.details.length &&
                  `${data.details[0].type}`}
              </p>
              <p className="h5 font-weight-bold mb-0">
                {data.details &&
                  data.details.length &&
                  `${data.currencyCode} ${CurrencyFormat(
                    data.details[0].sumInsured
                  )}`}
              </p>
            </MDBCol>
            <MDBCol md="5" className="px-5 py-4">
              <p className="create-text-small">Personal Accident Type</p>
              <p className="h5 font-weight-bold mb-0">
                {data.policyType
                  ? data.policyType === "PA-BASIC"
                    ? "PA Basic"
                    : "PA Basic Covid Protection"
                  : "PA Basic"}
              </p>
            </MDBCol>
            <MDBCol md="3" className="px-5 py-4">
              <p className="create-text-small">
                {data.details &&
                  data.details.length &&
                  `${data.details[1].type}`}
              </p>
              <p className="h5 font-weight-bold mb-0">
                {data.details &&
                  data.details.length &&
                  `${data.currencyCode} ${CurrencyFormat(
                    data.details[1].sumInsured
                  )}`}
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <img src={Ellipse} alt="ellipse" className="ellipse" />
        <img src={Healthcare} alt="healthcare" className="healthcare" />
      </div>
    </>
  )
}
