import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import {
  setDataBooking,
  setDataSummary,
  setDataFoto,
  setUserType,
} from "../../store/actions";
import { Link, useHistory } from "react-router-dom";
import PrimaryButton from "../../components/Button/PrimaryButton";
import SecondaryButton from "../../components/Button/SecondaryButton";
import Loading from "../../components/Loading";
import Jumbotron from "../../components/Jumbotron";
import axiosGet from "../../helpers/axiosGet";
import moment from "moment";
import Select from "react-select";

export default function Booking() {
  const scroll = useRef(null);
  const executeScroll = () => scroll.current.scrollIntoView();

  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state.bookingStore.dataBooking);
  const file = useSelector((state) => state.bookingStore.dataFoto);

  useEffect(() => {
    if (file.file) {
      setFoto(file.file);
    } else {
      setFoto(null);
    }

    axiosGet({
      url: `/policy/user-type`,
      callback: (res) => {
        dispatch(setUserType(res.data));
        setIsAdmin(res.data.isAdmin);
        setUsers(res.data.userlist);
        setLoading(false);
      },
      errorCallback: (res) => {
        setLoading(false);
      },
    });
  }, [dispatch, file.file]);

  const { register, handleSubmit, setError, errors, watch, control } = useForm({
    defaultValues: data,
  });

  const insuredType = watch("insuredType");

  const [foto, setFoto] = useState(null);
  const [fotoError, setFotoError] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const options = users.map((user) => {
    return {
      value: user.userCode,
      label: user.username,
    };
  });

  const handleFoto = (event) => {
    setFoto(event.target.files[0]);
    setFotoError(false);
  };

  const nextHandler = (data) => {
    const age = moment().diff(data.insuredBirthDate, "years");

    if (!foto) {
      setFotoError(true);
    } else if (age < 17) {
      setError("insuredBirthDate", {
        type: "manual",
        message: `*Min age is 17. Your age is ${age}.`,
        shouldFocus: true,
      });
    } else if (age > 65) {
      setError("insuredBirthDate", {
        type: "manual",
        message: `*Max age is 65. Your age is ${age}.`,
        shouldFocus: true,
      });
    } else {
      executeScroll();
      setLoading(true);
      dispatch(setDataBooking(data));

      axiosGet({
        url: `/policy/get-type?insuredBirthDate=${data.insuredBirthDate}&policyType=PA-BASIC&policyStartDate=${data.policyStartDate}`,
        callback: (res) => {
          setLoading(false);
          dispatch(setDataSummary(res.data));
          const url = URL.createObjectURL(foto);
          dispatch(
            setDataFoto({
              file: foto,
              url: url,
            })
          );

          history.push("/summary");
          window.scrollTo(0, 0);
        },
        errorCallback: (res) => {
          setLoading(false);
        },
      });
    }
  };

  return (
    <>
      <Jumbotron />
      <section className="create-main-content" ref={scroll}>
        <div className="text-center">
          <h3 className="font-weight-bold">IKHTISAR PERTANGGUNGAN</h3>
          <p className="mb-1">SCHEDULE PERSONAL ACCIDENT CO-PRODUCT</p>
          <hr className="create-divider-primary create-divider-big" />
        </div>

        {loading ? (
          <Loading />
        ) : (
          <form onSubmit={handleSubmit(nextHandler)} noValidate>
            <MDBContainer className="grey-text border rounded px-5 py-4 mt-5 create-bg-white">
              <MDBRow className="create-row-gap">
                {isAdmin && (
                  <MDBCol md="12">
                    <MDBRow>
                      <MDBCol md="6">
                        <label>
                          Invoice Billing To<span>*</span>
                        </label>

                        <Controller
                          as={<Select />}
                          options={options}
                          name="insured"
                          control={control}
                          rules={{ required: true }}
                          className="font-weight-normal"
                        />

                        {errors.insured && (
                          <p className="create-error-message">
                            *Invoice Billing To Required
                          </p>
                        )}
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                )}

                <MDBCol md="6">
                  <label>
                    Nama Tertanggung<span>*</span>
                  </label>
                  <input
                    placeholder="Nama Tertanggung"
                    type="text"
                    name="insuredName"
                    className="form-control"
                    ref={register({ required: true })}
                  />
                  {errors.insuredName && (
                    <p className="create-error-message">
                      *Nama Tertanggung Required
                    </p>
                  )}
                </MDBCol>

                <MDBCol md="6">
                  <label>
                    Jenis Kelamin<span>*</span>
                  </label>

                  <MDBRow>
                    <MDBCol md="4">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          value="Pria"
                          name="insuredGender"
                          id="insuredGenderMale"
                          ref={register({ required: true })}
                          className="custom-control-input"
                        />
                        <label
                          htmlFor="insuredGenderMale"
                          className="custom-control-label"
                        >
                          Pria
                        </label>
                      </div>
                    </MDBCol>
                    <MDBCol md="8">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          value="Wanita"
                          name="insuredGender"
                          ref={register({ required: true })}
                          id="insuredGenderFemale"
                          className="custom-control-input"
                        />
                        <label
                          htmlFor="insuredGenderFemale"
                          className="custom-control-label"
                        >
                          Wanita
                        </label>
                      </div>
                    </MDBCol>
                  </MDBRow>

                  {errors.insuredGender && (
                    <p className="create-error-message">
                      *Jenis Kelamin Required
                    </p>
                  )}
                </MDBCol>

                <MDBCol md="6">
                  <label>
                    Apakah anda Warga Negara Indonesia ?<span>*</span>
                  </label>

                  <MDBRow>
                    <MDBCol md="4">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          value="WNI"
                          name="insuredType"
                          id="insuredTypeWni"
                          ref={register({ required: true })}
                          className="custom-control-input"
                        />
                        <label
                          htmlFor="insuredTypeWni"
                          className="custom-control-label"
                        >
                          Ya
                        </label>
                      </div>
                    </MDBCol>
                    <MDBCol md="8">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          value="WNA"
                          name="insuredType"
                          ref={register({ required: true })}
                          id="insuredTypeWna"
                          className="custom-control-input"
                        />
                        <label
                          htmlFor="insuredTypeWna"
                          className="custom-control-label"
                        >
                          Tidak
                        </label>
                      </div>
                    </MDBCol>
                  </MDBRow>

                  {errors.insuredType && (
                    <p className="create-error-message">*Required</p>
                  )}
                </MDBCol>

                {insuredType === "WNI" && (
                  <MDBCol md="6">
                    <label>
                      No KTP<span>*</span>
                    </label>

                    <input
                      placeholder="No KTP"
                      type="number"
                      name="insuredIdentifyNumber"
                      className="form-control"
                      onKeyDown={(e) => {
                        if (
                          isNaN(e.key) &&
                          !(e.key === "Backspace" || e.key === "Tab")
                        )
                          e.preventDefault();
                      }}
                      ref={register({
                        required: "*No KTP Required",
                        minLength: {
                          value: 16,
                          message: "*No KTP must be 16 digit",
                        },
                        maxLength: {
                          value: 16,
                          message: "*No KTP must be 16 digit",
                        },
                      })}
                    />
                    {errors.insuredIdentifyNumber && (
                      <p className="create-error-message">
                        {errors.insuredIdentifyNumber.message}
                      </p>
                    )}
                  </MDBCol>
                )}

                {insuredType === "WNA" && (
                  <MDBCol md="6">
                    <label>
                      No KITAS<span>*</span>
                    </label>
                    <input
                      placeholder="No KITAS"
                      name="insuredIdentifyNumber"
                      className="form-control"
                      ref={register({ required: true })}
                    />
                    {errors.insuredIdentifyNumber && (
                      <p className="create-error-message">*No KITAS Required</p>
                    )}
                  </MDBCol>
                )}

                <MDBCol md="6">
                  <label>
                    Tempat Lahir<span>*</span>
                  </label>
                  <input
                    placeholder="Tempat Lahir"
                    type="text"
                    name="insuredBirthPlace"
                    className="form-control"
                    ref={register({ required: true })}
                  />
                  {errors.insuredBirthPlace && (
                    <p className="create-error-message">
                      *Tempat Lahir Required
                    </p>
                  )}
                </MDBCol>

                <MDBCol md="6">
                  <label>
                    Tanggal Lahir<span>*</span>
                  </label>
                  <input
                    name="insuredBirthDate"
                    type="date"
                    className="form-control"
                    ref={register({
                      required: "*Tanggal Lahir Required",
                    })}
                    max={moment().format("YYYY-MM-DD")}
                  />
                  {errors.insuredBirthDate && (
                    <p className="create-error-message">
                      {errors.insuredBirthDate.message}
                    </p>
                  )}
                </MDBCol>

                <MDBCol md="6">
                  <label>
                    No Telp<span>*</span>
                  </label>
                  <input
                    placeholder="No Telp"
                    type="number"
                    name="insuredPhoneNumber"
                    className="form-control"
                    ref={register({ required: true })}
                    onKeyDown={(e) => {
                      if (
                        isNaN(e.key) &&
                        !(e.key === "Backspace" || e.key === "Tab")
                      )
                        e.preventDefault();
                    }}
                  />
                  {errors.insuredPhoneNumber && (
                    <p className="create-error-message">*No Telp Required</p>
                  )}
                </MDBCol>

                <MDBCol md="6">
                  <label>
                    Email<span>*</span>
                  </label>
                  <input
                    placeholder="Email"
                    type="email"
                    name="insuredEmail"
                    className="form-control"
                    ref={register({
                      required: "*Email Required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "*Invalid email address",
                      },
                    })}
                  />
                  {errors.insuredEmail && (
                    <p className="create-error-message">
                      {errors.insuredEmail.message}
                    </p>
                  )}
                </MDBCol>

                <MDBCol md="6">
                  <label>
                    Alamat Tertanggung<span>*</span>
                  </label>
                  <textarea
                    placeholder="Alamat Tertanggung"
                    name="insuredAddress"
                    className="form-control"
                    ref={register({ required: true })}
                  />
                  {errors.insuredAddress && (
                    <p className="create-error-message">
                      *Alamat Tertanggung Required
                    </p>
                  )}
                </MDBCol>

                <MDBCol md="6">
                  <label>Nama Ahli Waris</label>
                  <input
                    placeholder="Nama Ahli Waris"
                    type="text"
                    name="insuredHeirName"
                    className="form-control"
                    ref={register}
                  />
                </MDBCol>

                <MDBCol md="6">
                  <label>
                    Upload Foto {insuredType === "WNI" ? "KTP" : "KITAS"}
                    <span>*</span>
                  </label>
                  <div className="custom-file">
                    <input
                      id="formFoto"
                      name="foto"
                      type="file"
                      className="custom-file-input"
                      accept="image/*"
                      onChange={handleFoto}
                    ></input>
                    <label className="custom-file-label" htmlFor="formFoto">
                      {foto ? (
                        <div
                          className="d-inline-block text-truncate mr-2"
                          style={{ maxWidth: "100%" }}
                        >
                          {foto.name}
                        </div>
                      ) : (
                        "Pilih File"
                      )}
                      <div
                        className="create-gradient-primary text-white font-weight-bold px-3 rounded-pill d-flex align-items-center justify-content-center"
                        style={{ fontSize: 12 }}
                      >
                        Upload
                      </div>
                    </label>

                    {fotoError && (
                      <p className="create-error-message">
                        *Foto {insuredType === "WNI" ? "KTP" : "KITAS"} Required
                      </p>
                    )}
                  </div>
                </MDBCol>

                <MDBCol md="6">
                  <label>
                    Tanggal Awal Pertanggungan<span>*</span>
                  </label>
                  <input
                    type="date"
                    name="policyStartDate"
                    className="form-control"
                    ref={register({ required: true })}
                    min={moment().subtract(30, "d").format("YYYY-MM-DD")}
                    max={moment().add(30, "d").format("YYYY-MM-DD")}
                  />
                  {errors.policyStartDate && (
                    <p className="create-error-message">
                      *Tanggal Awal Pertanggungan Required
                    </p>
                  )}
                </MDBCol>
              </MDBRow>
            </MDBContainer>

            <MDBContainer className="create-button-container">
              <MDBRow between>
                <Link to="/history" onClick={() => window.scrollTo(0, 0)}>
                  <SecondaryButton text="Back" />
                </Link>
                <PrimaryButton text="Next" type="submit" />
              </MDBRow>
            </MDBContainer>
          </form>
        )}
      </section>
    </>
  );
}
